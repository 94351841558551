.page {
    background: rgb(246,171,131);
background: linear-gradient(45deg, rgba(246,171,131,1) 9%, rgba(252,153,244,1) 18%, rgba(255,255,255,1) 42%);
}
.floating { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
 
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 5px); }
    100%   { transform: translate(0, -0px); }   
}