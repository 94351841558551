/* .two{
    transform: translateX(20px)
}
.three{
    transform: translateX(40px)
}
.one{
    transform:translateX(0px)
} */

.wrapper{
    transition:0.3s ease-in-out;
    transform:skewX(-15deg);
    box-shadow:6px 6px 0px #f6ab83;
    width:fit-content;
}

.three{
    transform:translateX(0px);
}
.two{
    transition:0.2s ease-in-out;
    transform:translateX(-5px);
}
.one{
    transition:0.3s ease-in-out;
    transform:translateX(-10px);
}
.arrows{
    transition:0.3s ease-in-out;
}
/* hover */

.wrapper:hover{
    box-shadow:10px 10px 0px #fc99f4;
}

.wrapper:hover .three{
    transform:translateX(0px);
    animation: anim 1s infinite 0.2s;
}
.wrapper:hover .two{
    transform:translateX(0px);
    animation: anim 1s infinite 0.4s;
}

.wrapper:hover .one{
    transform:translateX(0px);
    animation: anim 1s infinite 0.6s;
}

.wrapper:hover .arrows{
    margin-right:10px;
}

@keyframes anim {
    0% {
        fill: white;
    }
    50% {
        fill: #fc99f4;
    }
    100% {
        fill: white;
    }
}