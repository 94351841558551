.leaderboard-table::-webkit-scrollbar-button {
    display: none;
}

.leaderboard-table::-webkit-scrollbar {
    width: 16px;
}

.leaderboard-table::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 100px;
}

.leaderboard-table::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;

}