.quiz {
    background-image: url("../../Assets/images/rainbow.png ");
    background-size: cover;
    background-attachment: fixed;
}

.progress-bar-container {}

.progress-bar-indicator {
    background: rgb(246, 173, 131);
    background: linear-gradient(90deg, rgba(246, 173, 131, 1) 0%, rgba(219, 88, 209, 1) 100%);
}